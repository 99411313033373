<!-- rakumart&1688 -->
<template>
  <div class="accouPage">
    <headGoBack :iCustomizdde="true">
      <template #default>
        <span> RAKUAMRT&1688 </span>
      </template>
    </headGoBack>
    <div class="bannerBox">
      <h3>RAKUAMRT&1688 共同プロジェクト</h3>
      <span>アリババグループアカウント　 連結登録</span>
    </div>
    <div class="rowBox">
      <h2 class="rowTitle">
        RAKUMARTは、会員様に最も便利な輸入ビジネスサポート・サービスを提供する事に尽力しています。
      </h2>
      <p>
        現在RAKUMARTサイトで会員登録すれば、自動的にアリババ・アカウントを取得する事が可能です。
      </p>
      <p>
        この機能により、余分な煩わしい手続き不要で中国の大型ショッピング・サイトの豊富な商品情報を簡単に閲覧し、精度の高い中国輸入ビジ
      </p>
      <img
        style="width: 8.93333rem; height: 7.46667rem"
        src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202311/65682c01aedb7.png"
        alt=""
      />
    </div>
    <div class="rowBox whiteRow">
      <h3>連結によるメリット</h3>
      <h4>時間と手間の節約</h4>
      <p>
        RAKUMARTとAlibabaのアカウントを同期させることで、複数のプラットフォームに別々にアカウントを登録する手間が省けます。
      </p>
      <p>
        一つのステップでAlibabaのグループサイト（1688、Tmall、Taobao）を簡単にアカウント作成、閲覧、必要な商品を見つけることができます。
      </p>
      <p>
        弊社はすべてを簡略化し、迅速かつ効率的に注文を行えるようにさせていただきました。迅速かつ効率的に行えるようにさせていただきました。
      </p>
      <img
        style="width: 8.4rem; height: 6.66667rem"
        src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202311/65682e10c5969.png"
        alt=""
      />
      <h3>凍結リスクの軽減</h3>
      <p>
        RAKUMARTで同期登録されたアカウントを利用することで、アカウントが海外で使用されることによってアカウントが停止されるリスクを大幅に少なくすることができます。
      </p>
      <img
        style="width: 9.2rem; height: 7.06667rem"
        src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202311/65682e83ad4ba.png"
        alt=""
      />
    </div>
    <div class="rowBox">
      <h3>今すぐ始める</h3>
      <div class="jpCon">
        <span>RAKUMART未登録の方</span>
        <button @click="$fun.routerToPage('/register')">新規登録へ</button>
      </div>
      <div class="jpCon">
        <span>RAKUMARTアカウントお持ちの方</span>
        <button class="whiteButton">連結へ</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import headGoBack from "@/components/headGoBack";
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
//------------------------ methods -------------------------------------
//------------------------ pageLoad -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
* {
  font-size: 24px;
  line-height: 1.5;
}
.accouPage {
  background-color: #f6f6f6;
  .bannerBox {
    background-image: url("https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202311/656826314a013.png");
    background-position: center center;
    background-size: auto 100%;
    width: 750px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
      color: white;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 40px;
    }
    span {
      color: white;
      font-size: 30px;
      font-weight: 400;
    }
  }
  .rowBox {
    padding: 70px 40px 10px;
    .rowTitle {
      margin-bottom: 50px;
    }
    h3 {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      line-height: 42px;
      margin-bottom: 40px;
    }
    h4 {
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 25px;
    }
    p {
      line-height: 36px;
      margin-bottom: 20px;
    }
    img {
      margin: 60px 0 110px;
      //   width: 630px;
      //   height: 560px;
    }
    .jpCon {
      width: 670px;
      height: 322px;
      background: #ffffff;
      border-radius: 20px;
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 120px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span {
        font-size: 24px;
        line-height: 42px;
        margin-bottom: 30px;
      }
      button {
        width: 320px;
        height: 80px;
        background: #b4272b;
        border-radius: 8px;
        font-size: 28px;
        font-weight: bold;
        color: #ffffff;
        &:active {
          opacity: 0.7;
        }
      }
      .whiteButton {
        background: #ffffff;
        border: 2px solid #b4272b;
        color: #b4272b;
      }
    }
  }
  .whiteRow {
    background-color: white;
  }
}
</style>
